<template>
  <div :class="$style.add">
    <div :class="$style.box" @click="onAdd()">
      <i class="ri-add-line" />
      <p>新建空白页面</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'add',
  data() {
    return {}
  },
  methods: {
    onAdd() {
      this.$store.commit('EDITOR', {
        page_type: 'template',
        id: null,
      })
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'editor',
      })
    },
  },
}
</script>

<style lang="less" module>
.add {
  display: flex;

  .box {
    width: 280px;
    height: 606px;
    border: 1px solid #eee;
    background: #fff;
    text-align: center;
    font-size: 14px;
    padding-top: 238px;
    cursor: pointer;

    i {
      font-size: 60px;
      color: #5c5c5c;
    }

    .tip {
      margin-top: 10px;
    }
  }
}
</style>
